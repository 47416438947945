import axios from "axios";
import {
    BrokerExportRequestDTO,
    BrokerPreferredContactMethodDTO,
    BulkUploadRequestDTO,
    CarrierBrokerStatusDTO,
    CarrierDTO,
    CreateCarrierUserBindingModel,
    FinishedMetricsExportFilesDTO,
    HodUserRoleDTO,
    LeadPreferredContactMethodDTO,
    LeadStatusDTO,
    MetricsDataTypeDTO,
    MetricsExportBindingModel,
    ReferralBulkUploadReportRowDTO,
    SaveExportBrokerRequestBindingModel,
    SuspensionReasonDTO,
    TimezoneDTO,
    UserTypeDTO,
    WirelessProviderDTO,
    ZipCodeDataDTO
} from "../types";
import AuthAPI from "./AuthAPI";
import BaseAPI from "./BaseAPI";
import {BrandingDocument} from "../BrandingTypes";
import {CountyDTO} from "../clientConfiguratorTypes";
import * as ExpertBulkEdit from '../library/ExpertBulkEdit';

type DoesEmailExistResponse = {
    hiddenUser: CreateCarrierUserBindingModel,
    newUser: CreateCarrierUserBindingModel
}

export default class GeneralAPI extends BaseAPI {
    static async get_time_zones(): Promise<TimezoneDTO[]> {
        return (await axios.get('api/general/time-zones')).data;
    }

    static async get_broker_preferred_contact_methods(): Promise<BrokerPreferredContactMethodDTO[]> {
        return (await axios.get('api/general/broker-preferred-contact-methods')).data;
    }

    static async lead_status(carrier_guid: string): Promise<LeadStatusDTO[]> {
        return (await axios.get(`api/general/lead-status/${carrier_guid}`)).data;
    }

    static async carrier_broker_status(): Promise<CarrierBrokerStatusDTO[]> {
        return (await axios.get('api/general/carrier-broker-status/')).data;
    }

    static async carrier_broker_suspension_reason(): Promise<SuspensionReasonDTO[]> {
        return (await axios.get('api/general/carrier-broker-suspended-reason/')).data;
    }

    static async get_zip_code_data(zip_code: string): Promise<ZipCodeDataDTO[]> {
        return (await axios.get(`api/general/zip-code/${zip_code}`)).data;
    }

    static async get_lead_preferred_contact_methods(): Promise<LeadPreferredContactMethodDTO[]> {
        return (await axios.get('api/general/lead-preferred-contact-methods')).data;
    }

    static async validate_referral_postal_code(carrier_guid: string, zip_code: string, token: string): Promise<string | null> {
        return (await axios.get(`api/general/carrier-referral-postal-validation/${carrier_guid}/zip/${zip_code}`, AuthAPI.bearer_token(token))).data;
    }

    static async get_latest_export_files(user_id: string, token: string): Promise<FinishedMetricsExportFilesDTO[]> {
        return (await axios.post(`api/general/get-latest-export-files/${user_id}`, null, AuthAPI.bearer_token(token))).data;
    }

    static async save_export_metrics_request(model: MetricsExportBindingModel, token: string) {
        const params = new URLSearchParams();
        params.set('fromDate', model.fromDate);
        params.set('toDate', model.toDate);
        params.set('reportType', `${model.reportType}`);
        params.set('timeZoneId', `${model.timezoneId}`);
        params.set('userId', model.userId);
        params.set('carrierGuid', model.carrierGuid);
        if (model.brokerId) {
            params.set('brokerId', `${model.brokerId}`);
        }
        await axios.post('api/general/save-export-metrics-request', params, AuthAPI.bearer_token(token));
    }

    static async user_types(token: string): Promise<UserTypeDTO[]> {
        return (await axios.get('api/general/userTypes', AuthAPI.bearer_token(token))).data;
    }

    static async does_email_exist_for_hidden_user(model: CreateCarrierUserBindingModel, token: string): Promise<DoesEmailExistResponse> {
        return (await axios.post('api/general/does-email-exist-for-hidden-user', model, AuthAPI.bearer_token(token))).data;
    }

    static async get_carrier_by_guid(carrier_guid: string): Promise<CarrierDTO> {
        return (await axios.get(`api/general/get-carrier-by-guid/${carrier_guid}`)).data;
    }

    static async get_wireless_providers(): Promise<WirelessProviderDTO[]> {
        return (await axios.get('api/general/wireless-providers')).data;
    }

    static async get_metrics_data_types(): Promise<MetricsDataTypeDTO[]> {
        return (await axios.get('api/general/metrics-data-types')).data;
    }

    static async lead_status_selectable(carrier_guid: string): Promise<LeadStatusDTO[]> {
        return (await axios.get(`api/general/lead-status-selectable/${carrier_guid}`)).data;
    }

    static async get_hod_user_roles(): Promise<HodUserRoleDTO[]> {
        return (await axios.get('api/general/get-hod-user-roles')).data;
    }

    static async get_branding_document(banner_name: string): Promise<BrandingDocument> {
        return (await axios.get(`api/general/get-branding-contents/${banner_name}`)).data;
    }

    static async get_counties(states: string[], access_token: string): Promise<CountyDTO[]> {
        const params = new URLSearchParams();
        states.map((state, index) => {
            params.append(`states[]`, state);
        })
        return (await axios.get(`api/general/get-counties?${params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_city_name_by_post_code_id(post_code_id: string): Promise<string> {
        return (await axios.get(`api/general/getCityNameByPostalCodeId/${post_code_id}`)).data;
    }

    static async get_referral_bulk_upload_requests(access_token: string): Promise<BulkUploadRequestDTO[]> {
        return (await axios.get(`api/general/referral-bulk-upload/get-requests`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_referral_bulk_upload_request_report(request_id: number, access_token: string): Promise<ReferralBulkUploadReportRowDTO[]> {
        return (await axios.get(`api/general/referral-bulk-upload/report/${request_id}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async cancel_referral_bulk_upload_request(request_id: number, access_token: string) {
        await axios.post(`api/general/referral-bulk-upload/request/${request_id}/cancel`, null, AuthAPI.bearer_token(access_token));
    }

    static async replace_referral_bulk_upload_request_file(request_id: number, file: File, access_token: string) {
        const form_data = new FormData();
        form_data.append('data', file);
        await axios.post(`api/general/referral-bulk-upload/request/${request_id}/replace`, form_data, AuthAPI.bearer_token(access_token));
    }

    static async download_referral_bulk_upload_request_file(request_id: number, access_token: string) {
        return (await axios.get<string>(`api/general/referral-bulk-upload/request/${request_id}/download`, AuthAPI.bearer_token(access_token))).data;
    }

    static async finalize_referral_bulk_upload_request(request_id: number, access_token: string) {
        await axios.post(`api/general/referral-bulk-upload/request/${request_id}/finalize`, null, AuthAPI.bearer_token(access_token));
    }

    static async save_export_broker_request(model: SaveExportBrokerRequestBindingModel, access_token: string) {
        await axios.post(`api/general/save-export-brokers`, model, AuthAPI.bearer_token(access_token));
    }

    static async list_latest_export_broker_requests_for_user(access_token: string): Promise<BrokerExportRequestDTO[]> {
        return (await axios.get<BrokerExportRequestDTO[]>(`api/general/latest-export-broker-requests`, AuthAPI.bearer_token(access_token))).data;
    }

    static async remove_broker_report_request(request_id: number, access_token: string) {
        return (await axios.put(`api/general/hide-export-broker-file/${request_id}`, null, AuthAPI.bearer_token(access_token)));
    }

    static async getPendingExpertBulkUploadRequests(token: string): Promise<BulkUploadRequestDTO[]> {
        return (await this.authenticatedAxiosInstance(token).get<BulkUploadRequestDTO[]>(`api/general/expert-bulk-upload/get-requests`)).data;
    }

    static async replaceExpertBulkUpload(token: string, requestId: number, file: NonNullable<File>) {
        const formData = new FormData();
        formData.append('data', file);
        await BaseAPI.authenticatedAxiosInstance(token).post(`api/general/expert-bulk-upload/request/${requestId}/replace`, formData);
    }

    static async cancelExpertBulkUpload(token: string, requestId: number) {
        await BaseAPI.authenticatedAxiosInstance(token).post(`api/general/expert-bulk-upload/request/${requestId}/cancel`);
    }

    static async finalizeExpertBulkUpload(token: string, requestId: number) {
        await BaseAPI.authenticatedAxiosInstance(token).post(`api/general/expert-bulk-upload/request/${requestId}/finalize`);
    }

    static async downloadExpertBulkUpload(token: string, requestId: number): Promise<string> {
        return (await BaseAPI.authenticatedAxiosInstance(token).get<string>(`api/general/expert-bulk-upload/request/${requestId}/download`)).data;
    }

    static async getExpertBulkUploadRequestReport(token: string, requestId: number): Promise<ExpertBulkUpload.ReportRow[]> {
        return (await BaseAPI.authenticatedAxiosInstance(token).get<ExpertBulkUpload.ReportRow[]>(`api/general/expert-bulk-upload/report/${requestId}`)).data;
    }

    static async getExpertBulkEditRequests(accessToken: string): Promise<ExpertBulkEdit.ExpertBulkEditRequestDTO[]> {
        return (await BaseAPI.authenticatedAxiosInstance(accessToken).get('api/general/expert-bulk-edit/get-requests')).data;
    }

    static async cancelExpertBulkEditRequest(accessToken: string, requestId: string) {
        await BaseAPI.authenticatedAxiosInstance(accessToken).post(`api/general/expert-bulk-edit/request/${requestId}/cancel`);
    }

    static async finalizeExpertBulkEditRequest(accessToken: string, requestId: string) {
        await BaseAPI.authenticatedAxiosInstance(accessToken).post(`api/general/expert-bulk-edit/request/${requestId}/finalize`);
    }

    static async downloadExpertBulkEditRequest(accessToken: string, requestId: string) {
        return (await BaseAPI.authenticatedAxiosInstance(accessToken).get<string>(`api/general/expert-bulk-edit/request/${requestId}/download`)).data;
    }

    static async expertBulkEditRequestReport(accessToken: string, requestId: string): Promise<ExpertBulkEdit.ReportRow[]> {
        return (await BaseAPI.authenticatedAxiosInstance(accessToken).get<ExpertBulkEdit.ReportRow[]>(`api/general/expert-bulk-edit/report/${requestId}`)).data
    }

    static async expertBulkEditReplaceFile(accessToken: string, requestId: string, file: NonNullable<File>) {
        const formData = new FormData();
        formData.append('data', file);
        await BaseAPI.authenticatedAxiosInstance(accessToken).post(`api/general/expert-bulk-edit/request/${requestId}/replace`, formData);
    }

}
